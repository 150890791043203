{
  "name": "jackpot-fe",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4203",
    "start:dev": "ng serve --configuration dev --port 4203",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.9",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.9",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@ngneat/until-destroy": "^10.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.7",
    "@angular-eslint/builder": "17.4.1",
    "@angular-eslint/eslint-plugin": "17.4.1",
    "@angular-eslint/eslint-plugin-template": "17.4.1",
    "@angular-eslint/schematics": "17.4.1",
    "@angular-eslint/template-parser": "17.4.1",
    "@angular/cli": "^17.3.7",
    "@angular/compiler-cli": "^17.3.0",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "7.8.0",
    "@typescript-eslint/parser": "7.8.0",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.1.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.5",
    "typescript": "~5.4.2"
  }
}

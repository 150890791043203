import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Theme } from '@shared/types/theme.type';
import { UserInfo } from '@shared/types/user.type';
import packageInfo from '../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private _user = new BehaviorSubject<UserInfo | undefined>(undefined);
  isMobile = false;
  thirdPartyToken = '';
  gameUuid?: string;
  instance?: string;
  theme?: Theme;
  readonly version = packageInfo.version;
  readonly user$ = this._user.asObservable();

  constructor() {}

  setUser(userInfo: UserInfo): void {
    this._user.next(userInfo);
  }
}
